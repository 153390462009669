import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GlobalStyle } from "config-styles";
// Components
import Seo from "components/Seo";

// Assets
import ImageGooglePlay from "../sections/global/CtaApp/ImageGooglePlay";
import ImageAppStore from "../sections/global/CtaApp/ImageAppStore";
import Button from "components/Button";
import { Wrapper as Btn } from "components/Button/styles";

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  height: 100vh;
`;
const Content = styled.div`
  width: 100%;
  max-width: 240px;

  h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }

  ${Btn} {
    margin-top: 15px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
  }
`;
const List = styled.ul`
  margin: 20px 0;
  li + li {
    margin-top: 10px;
  }
`;

const Image = styled.img`
  margin-left: -20px;
  margin-bottom: 15px;
  width: 100%;
`;

function Download() {
  const {
    site: {
      siteMetadata: { googlePlayUrl, appStoreUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          googlePlayUrl
          appStoreUrl
        }
      }
    }
  `);

  return (
    <>
      <Seo title="Download do app" description="Baixe o app do tecimob" />

      <Wrapper>
        <Content>
          <Image src="/imagem-aplicativo.png" alt="Aplicativo" />
          <h2>Baixe nosso aplicativo</h2>
          <p>
            Faça login com os dados que cadastrou agora e leve o Tecimob no seu
            bolso.
          </p>
          <List>
            <li>
              <a
                aria-label="Aplicativo da GooglePlay"
                href={googlePlayUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageGooglePlay />
              </a>
            </li>
            <li>
              <a
                aria-label="Aplicativo da AppStore"
                href={appStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageAppStore />
              </a>
            </li>
          </List>
          <p>Ou acesse o Tecimob com os mesmos dados pelo seu computador.</p>
          <Button
            as="a"
            href="https://tecimob.com.br"
            kind="solid"
            color="secondary"
          >
            Voltar para o site
          </Button>
        </Content>
      </Wrapper>

      <GlobalStyle />
    </>
  );
}

export default Download;
